/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Suspense } from 'react';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';

import styles from './views.module.css';
import SidebarComponent from '../shared/sidebar/sidebar.component';
import viewsRouter from './viewsRouter';
import AuthService from '../core/auth.service';
import ViewsService from './views.service';
import RouterService from '../core/router.service';
import { USER_TYPE } from '../enums/userRoles';

const UserProfileComponent = React.lazy(() => import('../components/profile/UserProfileComponent'));
const UserComponent = React.lazy(() => import('../components/user/UserComponent'));
function ViewsComponent({ callBackGetVersion }) {
  const location = useLocation();
  const navigate = useNavigate();
  const publicPaths = ['/', '/login', '/forgot-password', '/reset-password'];
  const userIdentity = AuthService.getUserIdentity();
  const [isAnErrorUrl, setIsAnErrorUrl] = useState();
  const [isSidebarToggled, setIsSidebarToggled] = useState(true);
  const [executedGetLastAccess, setExecutedGetLastAccess] = useState(false);
  const errorUrlArray = [
    `${process.env.REACT_APP_SUBFOLDER}/not-found`,
    `${process.env.REACT_APP_SUBFOLDER}/access-denied`,
    `${process.env.REACT_APP_SUBFOLDER}/request-timed-out`,
    `${process.env.REACT_APP_SUBFOLDER}/maintenance`,
    `${process.env.REACT_APP_SUBFOLDER}/request-timeout`,
  ];

  const navigateToDefaultLanding = () => {
    const destination = userIdentity.roleId === USER_TYPE.superadmin
      ? `${process.env.REACT_APP_SUBFOLDER}/organisations`
      : `${process.env.REACT_APP_SUBFOLDER}/campaigns`;
    navigate(destination);
  };

  const getPathname = (roleId, url) => {
    if (RouterService.isValidUrl(url)) {
      if (roleId === USER_TYPE.superadmin) {
        if (RouterService.isAccessibleForSuperAdmin(url)) return url;
        else return '/access-denied';
      } else if (roleId === USER_TYPE.organisationadmin) {
        if (RouterService.isAccessibleForOrganisationalAdmin(url)) return url;
        else return '/access-denied';
      } else {
        if (RouterService.isAccessibleForOrganisationalUser(url)) return url;
        else return '/access-denied';
      }
    } else {
      return '/not-found';
    }
  };

  const handleSaveLastAccess = (pathname) => {
    if (!isAnErrorUrl) {
      ViewsService.saveUserLastAccess({ userId: userIdentity.userId, url: pathname });
    }
  };

  const handleRoute = (pathname) => {
    const isPublic = publicPaths.some((path) => path === location.pathname);
    const lastAccessedPage = userIdentity.lastAccessedPage;
    const hasLastAccessedPage = !executedGetLastAccess && !isAnErrorUrl && lastAccessedPage;
    const destination = hasLastAccessedPage ? lastAccessedPage : pathname;
    if (pathname === '/' || pathname === '/login' || isPublic) {
      navigateToDefaultLanding();
    } else {
      const pathname = getPathname(userIdentity.roleId, destination);
      navigate(process.env.REACT_APP_SUBFOLDER + pathname);
    }
  };

  useEffect(() => {
    setIsAnErrorUrl(errorUrlArray.includes(location.pathname) || location.pathname.includes('internal-server'));
    handleRoute(location.pathname);
    // handleSaveLastAccess(location.pathname);
    setExecutedGetLastAccess(true);
    callBackGetVersion();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className={styles.views}>
      <div
        className={`${styles.sidebar} ${isSidebarToggled ? styles.vaiShrinkSidebar : ''}`}
        style={{ display: isAnErrorUrl ? 'none' : '' }}
      >
        <SidebarComponent
          sidebarState={isSidebarToggled}
          toggleSidebar={(state) => setIsSidebarToggled(state)}
        />
      </div>
      <div className={isAnErrorUrl ? styles.errorPage : styles.content}>
        <Routes>
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/profile`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <UserProfileComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/users`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <UserComponent />
              </Suspense>
            }
          />
          {viewsRouter.routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<div>Loading</div>}>
                  {route.element}
                </Suspense>
              }
            />
          ))}
        </Routes>
      </div>
    </div>
  );
}
export default ViewsComponent;
