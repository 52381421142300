/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './forms.css';
import clsx from 'clsx';
import { capitalize } from 'lodash';

function InputLabel({
  label = '', disabled, className, required, name, errors, errorName, ...props
}) {
  const renderError = (message) => {
    const res = typeof message === 'string'
      ? <p className="is-danger capitalize">{capitalize(message.replace(/([A-Z])/g, ' $1').trim())}</p> : null;
    return res;
  };
  const error = errors && errors[errorName ?? name];
  return (
    <div className="w-100 gap-5 d-flex flex-column overflow-anywhere">
      {label && (
        <span className="input-label">
          {label}
          {required && (
            <span className="input-required">*</span>
          )}
        </span>
      )}
      <div className="d-flex flex-column">
        <input
          className={clsx({
            [className]: !!className,
            'input p-2 rounded': true,
            disabled,
          })}
          style={{ height: '35px'}}
          name={name}
          {...props}
          disabled={disabled}
          required={required}
        />
        {error && renderError(error)}
      </div>
    </div>
  );
}

export default InputLabel;
