/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import { isEmpty } from 'lodash';
import InputLabel from '../../Forms/InputLabel';
import {allow1Decimal, allow1Point, allow2Decimals, formatDateFromSetup} from '../helpers/helpers';
import useStore from "../hooks/useCampaignStore";

function WeeklySpendFields({
                             values,
                             errors,
                             index, onChange, startWeek, endWeek, noWeek,
                             isCampaignActive,
                           }) {
  const {
    dateFormat,
  } = useStore((state) => state);

  const error = isEmpty(errors) ? {} : errors.weeklySpend[index];

  const handleDecimal = (e) => {
    let val = e.target.value;
    const maxValue = 100;

    if (val.length > 1) val = val.replace(/^0+/, '');

    if (val > maxValue) return;
    val = val.replace('..', '.');

    if (val.includes('.')) {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allow1Point(allow2Decimals(e, val));

    onChange(e);
  };

  return (
      <div className="d-flex align-items-center r-container w-100 gap-2">
        <span className="remove-top  w-274px">{`Week ${noWeek + 1}, ${formatDateFromSetup(startWeek, dateFormat)} - ${formatDateFromSetup(endWeek, dateFormat)}`}</span>
        <div className="d-flex gap-2 w-auto align-items-start m-w-30">
          <div className="d-flex w-85px">
            <InputLabel maxLength={5} errorName="allocation" className="text-align" name={`weeklySpend[${index}].allocation`} errors={error} value={values[index].allocation} onChange={handleDecimal} disabled={!isCampaignActive} />
          </div>
        </div>
      </div>
  );
}

export default WeeklySpendFields;
