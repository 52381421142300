/* eslint-disable import/no-cycle */
// import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const useStore = createWithEqualityFn((set) => ({
  toPath: '',
  setToPath: (data) => set(() => ({ toPath: data })),
  isNavClicked: false,
  setIsNavClicked: (data) => set(() => ({ isNavClicked: data })),
}));

const useSidenavStore = (callback) => useStore(callback, shallow);

export default useSidenavStore;
