/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import moment from 'moment';
import useStore from './useCampaignStore';

export const getTotalWeeks = (STARTDATE, ENDDATE) => {
  if (STARTDATE && ENDDATE) {
    const start = moment(STARTDATE).weekday(1);
    const end = moment(ENDDATE).weekday(7);
    const diff = moment.duration(end.diff(start));
    return Math.round(diff.asWeeks());
  }
};

const useWeeklySpend = (STARTDATE, ENDDATE) => {
  const { setWeeklySpendDates, weeklySpendDates } = useStore((state) => state);

  useEffect(() => {
    if (weeklySpendDates) {
      const weekRange = [];
      if (!!STARTDATE && !!ENDDATE && !weeklySpendDates[0]?.startWeek) {
        let start = STARTDATE;
        weeklySpendDates.forEach((val) => {
          const srtd = moment(start).zone("+0000").startOf('week'); //moment(start).locale('en').isoWeekday('Monday');
          const endd = moment(start).zone("+0000").endOf('week'); //moment(start).locale('en').isoWeekday('Sunday');
          weekRange.push({
            ...val,
            startWeek: srtd,
            endWeek: endd,
          });
          start = endd.add(1, 'days');
        });

        setWeeklySpendDates(weekRange.map((week) => ({
          ...week,
          startWeek: week.startWeek.add(1, 'days'),
          endWeek: week.endWeek,
        })));
      }
    }
  }, [STARTDATE, ENDDATE, weeklySpendDates]);
};

export default useWeeklySpend;
