/* eslint-disable quotes */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import moment from "moment";

export const preSubmitForm = ({
  validateForm,
  setErrors,
  setChangeFormTab,
  setProceedAndSave,
  showConfirmLeaveModal,
  setShowConfirmLeaveModal,
  cancelNavigation,
  proceedFromDirtyForm,
  submitForm,
}) => {

  validateForm().then((res) => {
    setErrors(res);
    setChangeFormTab(false);
    setProceedAndSave(false);
    const hasError = Object.values(res).some((o) => o);
    if (hasError) {
      if (showConfirmLeaveModal) setShowConfirmLeaveModal(false);
      else cancelNavigation();
    } else if (proceedFromDirtyForm) {
      submitForm();
    }
  });
};

export const setDateAttribute = (element, value, format) => {
  const dateformat = format ?? element.getAttribute("data-date-format");
  const formatDate = moment(value, "YYYY-MM-DD").format(dateformat);
  element.setAttribute("data-date", value ? formatDate : dateformat);
};

export const fetchDefaultDateById = (key, format) => {
  const element = document.getElementById(key);
  if (element?.value) {
    setDateAttribute(element, element?.value, format);
    return;
  }
  if (element) {
    element.setAttribute("data-date", format);
  }
};
