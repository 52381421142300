/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import Button from '../../../shared/components/button/Button';

function UnsaveFormWarning({ handleProceed, handleDiscard, onCancel }) {
  return (
    <div className="d-flex w-100 flex-column gap-4 justify-content-center">
      <p>
        You have unsved changes, are you sure you want to navigate away from this page?
      </p>
      <div className="d-flex w-100 flex-column justify-content-center gap-2">
        <Button onClick={handleProceed} text="Save and proceed" />
        <Button onClick={handleDiscard} text="Discard and proceed" />
        <span className="d-flex justify-content-center w-100 dialog-close-btn pt-3" onClick={onCancel}>Cancel</span>
      </div>
    </div>
  );
}

export default UnsaveFormWarning;
