import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './Modal.css';

export const Modal = (props) => {
  const {
    buttonAlign = 'center',
    children,
    cancelButtonName,
    confirmButtonName,
    enableButtons = true, // TODO: fix issue where buttonModel is undefined if this is false
    handleOnClose,
    handleOnProceed,
    isActionPending = false,
    visible,
    width = '550px',
    ...rest
  } = props;

  // animation for modal on open/close
  const animationSettings = { effect: 'Fade', duration: 250 };

  // refs
  const dialogRef = useRef(null);

  // button definitions
  const buttonDefinitions = [
    {
      click: handleOnClose ? () => handleOnClose() : () => {},
      buttonModel: {
        content: cancelButtonName || 'Close',
        isPrimary: false,
        disabled: isActionPending,
      },
    },
    ...(confirmButtonName ? [{
      click: handleOnProceed ? () => handleOnProceed() : () => {},
      buttonModel: {
        content: confirmButtonName,
        isPrimary: true,
        disabled: isActionPending,
      },
    }] : []),
  ];

  const handleOnOpen = (params) => {
    // prevent autofocus on first element in modal
    params.preventFocus = true;
  };

  /*
   *  TODO: fix issue where rendered children components clickable elements
   *  onChange/onClick event handlers are not working
   */

  return (
    <div className="op2mise-modal-container">
      <DialogComponent
        animationSettings={animationSettings}
        buttons={buttonDefinitions}
        cssClass={`op2mise-modal footer-align-${buttonAlign}`}
        isModal // provides dim overlay for background
        open={handleOnOpen}
        position={{ X: 'center', Y: 'center' }}
        ref={dialogRef}
        target="body" // target DOM element for position
        visible={visible}
        width={width}
        {...rest}
      >
        <div className="modal-content">
          {children}
        </div>
      </DialogComponent>
    </div>
  );
};

Modal.propTypes = {
  buttonAlign: PropTypes.string,
  cancelButtonName: PropTypes.string,
  confirmButtonName: PropTypes.string,
  enableButtons: PropTypes.bool,
  handleOnClose: PropTypes.func,
  handleOnProceed: PropTypes.func,
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
  width: PropTypes.string,
  rest: PropTypes.object, // handle any other non-defined properties
};
