/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './forms.css';
import clsx from 'clsx';
import { capitalize } from 'lodash';

function Switch({ options, onClick, disabled }) {
  return (
    <div className={`switch d-flex ${disabled ? 'disabled' : ''}`}>
      {options.map((option, key) => (
        <div
          className={clsx({
            'switch-container': true,
            'switch-active': option.active,
            'switch-inactive': !option.active,
          })}
          key={`${option.label}-${key}`}
          onClick={(e) => onClick(e, option)}
        >
          {capitalize(option.label)}

        </div>
      ))}
    </div>
  );
}

export default Switch;
