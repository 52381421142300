/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import useStore from './useCampaignStore';

const useTabSelector = (startDate, endDate, budget) => {
  const { setCampaignForms, campaignForms, selectedCampaign } = useStore((state) => state);
  useEffect(() => {
    if (selectedCampaign) {
      setCampaignForms(campaignForms.map((form) => {
        if (['marketSpend', 'weeklySpend'].some((prog) => prog === form.id)) {
          return {
            ...form,
            active: startDate && endDate && budget,
          };
        }
        return form;
      }));
    }
  }, [startDate, endDate, budget]);
};

export default useTabSelector;
