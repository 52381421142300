/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import useStore from './useCampaignStore';
import useSidenavStore from '../../../shared/sidebar/hooks/useSidenavStore';

const useDirtyForm = ({ setShowDialog, setProceedAndSave }) => {
  const {
    setIsFormTouched, setChangeFormTab, setIsFormSaved, setShowCampaignForms,
  } = useStore((state) => state);
  const { toPath, isNavClicked } = useSidenavStore((state) => state);

  const navigate = useNavigate();

  const gotoNav = () => {
    setShowCampaignForms(!isNavClicked);
  };

  const handleProceed = (submitForm, validateForm, setErrors) => {
    setShowDialog(false);
    validateForm().then((res) => {
      setErrors(res);
      setChangeFormTab(false);
      setProceedAndSave(false);
      setIsFormTouched(false);
      if (isEmpty(res)) {
        submitForm();
        setProceedAndSave(true);
      }
    });
  };

  const handleReset = () => {
    setShowDialog(false);
    setIsFormTouched(false);
    setChangeFormTab(false);
    setIsFormSaved(true);
    setProceedAndSave(false);
  };

  const handleCancel = () => handleReset();

  const handleDiscard = () => {
    handleReset();
    setIsFormSaved(true);
    if (toPath) navigate(toPath);
    gotoNav();
  };

  return {
    handleProceed,
    handleCancel,
    handleDiscard,
    gotoNav,
  };
};

export default useDirtyForm;
