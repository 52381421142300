/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './dialog.css';

function Dialog({
  children, heading, onClose, show, Content, isDirtyForm
}) {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(show);
  }, [show]);
  const handleClose = () => {
    if (onClose) onClose();
    setIsShow(false);
  };
  return (
    <Modal
      show={isShow}
      onHide={handleClose}
      centered
      style={{
        padding: '10px', boxShadow: '0px 15px 50px rgba(0, 0, 0, 0.15)', borderRadius: '5px', overflow: 'hidden',
      }}
    >
      <div
        className="d-flex flex-column p-5"
        style={{
          width: '500px', height: isDirtyForm ? 'auto' : '200px', flexDirection: 'column', columnGap: '10px', rowGap: '10px',
        }}
      >
        {
          Content || (
            <>
              <div className="d-flex justify-content-center align-items-center w-100" style={{ height: '150px', textAlign: 'center' }}>{children}</div>
              <span className="d-flex justify-content-center align-content-end w-100 dialog-close-btn" onClick={handleClose}>Close</span>
            </>
          )
        }
      </div>
    </Modal>
  );
}

export default Dialog;
