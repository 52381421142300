import React from 'react';
import PropTypes from 'prop-types';
import styles from './profile.module.css';
import { chevLeftCircleIcon } from '../../assets';
const ProfileComponent = ({
    sidebarState,
    toggleSidebar,
  }) => {

  const toggleSidebarState = () => {
    toggleSidebar(!sidebarState);
  };

  const onToggleSidebarByKeyboard = (e) => {
    if (e.key === 'Enter') toggleSidebarState();
  };

  return (
    <div className='position-relative w-100' style={{ marginBottom: '33px' }}>
      <img
        className={`
          ${styles.chevLeftCircleIcon}
          ${sidebarState ? styles.chevLeftRotateIcon : ''}
        `}
        style={{
          marginRight: sidebarState ? '-22px' : '-11px',
          marginTop: '79px',
          zIndex: 1,
        }}
        src={chevLeftCircleIcon}
        alt="hamburgerIcon"
        onClick={toggleSidebarState}
        onKeyDown={onToggleSidebarByKeyboard}
        role="presentation"
        title={sidebarState ? 'Expand Menu' : 'Collapse Menu' }
      />
    </div>
  );
};
ProfileComponent.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
ProfileComponent.displayName = 'ProfileComponent';
export default ProfileComponent;
