import PropTypes from 'prop-types';
import { MultiSelectComponent, Inject, VirtualScroll } from '@syncfusion/ej2-react-dropdowns';
import styles from './styles.module.css';

export const MultiSelectDropdown = (props) => {
    const {
        // Custom Props
        label, // The name of the field, will appear at the top
        errorMessage, // shows a red text message at the bottom of the field
        showLabelAsterisk, // shows a red asterisk to indicate that the field is required

        // SF Props
        id, // element id
        cssClass, // class for the root element multi-select
        fields, // the mapping on which property to be shown in the input and which value will return onChange
        dataSource, // list of items to be displayed on the popup when the dropdown is clicked
        value, // value binding for multi select
        placeholder, // the text shown when there is no selected on the list
        allowObjectBinding, // to enable value binding set this to true
        mode, // controls the mode of multiselect field
        showDropDownIcon, // shows a triangle icon to indicate that it is a dropdown
        ...rest // rest of the sf multiselectcomponent props
    } = props;
    return (
        <>
            {label && <label className={styles.fieldLabel}>{label}{showLabelAsterisk && <span>*</span>}</label>}
            <MultiSelectComponent
                id={id}
                cssClass={cssClass}
                fields={fields}
                dataSource={dataSource}
                value={value}
                placeholder={placeholder}
                allowObjectBinding={allowObjectBinding}
                mode={mode}
                showDropDownIcon={showDropDownIcon}
                enableVirtualization={dataSource.length > 50}
                allowFiltering
                {...rest}
            >
                <Inject services={[VirtualScroll]} />
            </MultiSelectComponent>
            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
        </>
    )
};

MultiSelectDropdown.propTypes = {
    // Custom Props
    label: PropTypes.string,
    errorMessage: PropTypes.string,

    // Sf Props
    id: PropTypes.string,
    cssClass: PropTypes.string,
    fields: PropTypes.shape(PropTypes.any),
    dataSource: PropTypes.shape(PropTypes.any),
    value: PropTypes.shape(PropTypes.any),
    placeholder: PropTypes.string,
    allowObjectBinding: PropTypes.bool,
    mode: PropTypes.string,
    showDropDownIcon: PropTypes.bool,
    rest: PropTypes.object,
};