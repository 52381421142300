/* eslint-disable import/no-cycle */
import { useEffect } from 'react';
import useStore from './useCampaignStore';

const useHandleUnsavedForm = (setter) => {
  const { changeFormTab, isFormSaved } = useStore((state) => state);
  useEffect(() => {
    if (changeFormTab && !isFormSaved) setter(true);
  }, [changeFormTab]);
};

export default useHandleUnsavedForm;
