/* eslint-disable import/no-cycle */
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

const useLoginStoreHelper = createWithEqualityFn((set) => ({
  isAuthenticated: false,
  setIsAuthenticated: (data) => set(() => ({ isAuthenticated: data })),
  profilePicture: false,
  setProfilePicture: (data) => set(() => ({ profilePicture: data })),
  username: false,
  setUsername: (data) => set(() => ({ username: data })),
  jobTitle: false,
  setJobTitle: (data) => set(() => ({ jobTitle: data })),
  isGetUserCalled: false,
  setIsGetUserCalled: (data) => set(() => ({ isGetUserCalled: data })),
}));

const useLoginStore = (callback) => useLoginStoreHelper(callback, shallow);

export default useLoginStore;
