const StringService = {
    isValidEmail(email) {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },

    cleanString(string) {
        return string.replace(/\s+/g, ' ').trim();
    },

    cleanStringsFromObject(parameters) {
        for (const key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                if (typeof parameters[key] === 'string') {
                    parameters[key] = this.cleanString(parameters[key]);
                }
            }
        }
        return parameters;
    },

    padLeft(str, char, length) {
        const padLength = Math.max(0, length - str.length);
        const padding = char.repeat(padLength);
        return padding + str;
    },

    removeDuplicates(inputString) {
        let stringArray = inputString.split(', ');
        let uniqueArray = [...new Set(stringArray)];
        let uniqueString = uniqueArray.join(', ');

        return uniqueString;
      },
};

export default StringService;
