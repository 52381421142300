import { Modal } from "react-bootstrap";
import styles from './styles.module.css';
import Button from "../button/Button";

const ConfirmLeaveModal = ({ show, onSave, onDiscard, onHide, isSaving, customButtonContent, lock }) => {
    return (
        <Modal
            size="md"
            show={show}
            onHide={lock ? null : onHide}
            aria-labelledby="confirm-leave-modal"
        >
            <Modal.Body className={styles.promptModalBody}>
                <div>
                    You have unsaved changes, are you sure you want to navigate away
                    from this page?
                </div>
                {customButtonContent ? (
                    customButtonContent
                ) : (
                    <div>
                        <div className={styles.button}>
                            <Button
                                isLoading={isSaving}
                                text="Save and proceed"
                                onClick={onSave}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.button}>
                            <Button
                                text="Discard changes and proceed"
                                onClick={onDiscard}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className={styles.button}>
                            <Button
                                text="Cancel"
                                defaultBtn
                                onClick={onHide}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmLeaveModal;