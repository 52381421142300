/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */

function CampaignContent({ created, name, campaignInFlight }) {
  const renderMessage = () => {
    if (created) {
      return "Campaign has been created.";
    } else if (campaignInFlight) {
      return "Campaign has been successfully saved. You may need to re-run all booking.";
    } else {
      return "Campaign has been successfully saved. You need to re-run all booking.";
    }
  };

  return (
    <p className="text-center">
      {renderMessage()}
    </p>
  );
}

export default CampaignContent;
